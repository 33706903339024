<template>
  <div>
    <div
      class="testimonial_basic basic_style_asset"
      :class="[{ as_list: renderAsList }, shape]"
      v-for="(page, index) in thePages"
      :key="index"
    >
      <UercRibbon v-if="isUercSurvey" />
      <div class="wrapper">
        <BasicHeader :logoSvg="displayLogo" :text="headerText" />
        <div class="mt-3">
          <span v-if="index === 0 && page.length > 0">&ldquo;</span
          ><span v-if="index > 0">&hellip;</span><span v-html="page" class="page-wrapper"></span
          ><span v-if="totalPageCount > 1 && index !== totalPageCount - 1">&hellip;</span
          ><span v-if="index === totalPageCount - 1 && page.length > 0">&rdquo;</span>
        </div>

        <div class="d-flex mt-4">
          <img :src="avatarUrl" class="headshot" v-if="avatarUrl" />
          <div class="avatar" v-else>
            <AvatarIcon />
          </div>
          <div class="author-name-etc pl-3">
            <p class="author-name m-0">{{ renderableTestimonial.attribution_line_1 }}</p>
            <small class="author-etc font-italic">{{ authorEtc }}</small>
          </div>

          <div v-if="showNps" class="nps pl-3 ml-auto mr-0 text-center">
            <div class="nps-score font-weight-bold">{{ renderableTestimonial.nps_score }}</div>
            <div class="nps-label font-weight-bold">NPS</div>
          </div>
        </div>
      </div>

      <BasicFooter
        :verifiedAt="renderableTestimonial.verified_at"
        :ueId="renderableTestimonial.identifier"
        :surveySummary="sourceText"
        class="mt-4"
      />
    </div>
  </div>
</template>
<script>
import paginate from '../lib/paginate'
import AssetSourceMixin from '@app/mixins/asset_source'
import HeaderTextMixin from '@app/mixins/header_text'
import BasicFooter from '@components/basic_asset_parts/BasicFooter.vue'
import BasicHeader from '@components/basic_asset_parts/BasicHeader.vue'
import AvatarIcon from './graphics/AvatarIcon.vue'
import UercRibbon from '@app/graphics/UercRibbon.vue'

export default {
  name: 'TestimonialBasic',
  components: { AvatarIcon, BasicHeader, BasicFooter, UercRibbon },
  mixins: [AssetSourceMixin, HeaderTextMixin],
  props: {
    renderableTestimonial: { type: Object, required: true },
    shape: { type: String, required: false, default: 'flex' },
    page: { type: Number, required: false, default: 1 },
    showAllPages: { type: Boolean, required: false, default: false },
    account: { type: Object, required: true },
    renderAsList: { type: Boolean, required: false, default: false },
  },
  methods: {
    calculateMaxPageChars(maxChars) {
      if (
        !this.renderableTestimonial.show_question ||
        !this.renderableTestimonial.question_text ||
        this.renderableTestimonial.show_title
      ) {
        return maxChars
      }

      const boldFontCoefficient = 1.75
      const titleOffset = Math.ceil(
        this.renderableTestimonial.question_text.length * boldFontCoefficient,
      )
      const charsMinusQuestion = maxChars - titleOffset

      return charsMinusQuestion
    },
  },
  computed: {
    // Only renders nps score when toggle is true and nps score is present
    avatarUrl() {
      if (!this.renderableTestimonial.enable_avatar) return ''

      return this.renderableTestimonial.recipient?.avatar_url
    },
    showNps() {
      return this.renderableTestimonial.show_nps && this.renderableTestimonial.nps_score
    },
    thePageNum() {
      return this.page || 1 // default to first page
    },
    // This is the _visible_ content, even for a single page. When showing a single
    // page this will be an array with the current page (only). When showAllPages == true
    // this will have all pages.
    thePages() {
      const all_pages = this.allThePages
      return this.showAllPages ? all_pages : [all_pages[this.thePageNum - 1]]
    },
    wideMaxPageChars() {
      return this.calculateMaxPageChars(220)
    },
    squareMaxPageChars() {
      return this.calculateMaxPageChars(800)
    },
    allThePages() {
      // skip pagination when shape is flex
      if (this.shape === 'flex') {
        return [this.renderableTestimonial.testimonial_text]
      }
      // @TODO The following *_max_chars are conservative guestimates...
      // The exact amount of real estate depends on title length, etc
      // The amount of bold text is also a factor
      const wide_max_chars = this.wideMaxPageChars
      const square_max_chars = this.squareMaxPageChars
      const max_page_chars = this.shape === 'wide' ? wide_max_chars : square_max_chars
      return paginate(this.renderableTestimonial.testimonial_text, max_page_chars)
    },
    totalPageCount() {
      return this.allThePages.length
    },
    fill() {
      return this.theme == 'dark' ? 'white' : '#3a22ff'
    },
    authorEtc() {
      return [
        this.renderableTestimonial.attribution_line_2,
        this.renderableTestimonial.attribution_line_3,
      ]
        .filter(Boolean)
        .join(', ')
    },
    displayLogo() {
      switch (this.renderableTestimonial.logo_size) {
        case 'none':
          return ''
        case 'small':
          return this.account?.svg_logo_mark
        case 'large':
          return this.account?.svg_logo
        default:
          return ''
      }
    },
    // Necessary for Mixins to work
    contentAsset() {
      return this.renderableTestimonial
    },
    isUercSurvey() {
      return this.contentAsset?.survey?.type === 'UercSurvey'
    },
  },
}
</script>
<style lang="sass" scoped>
*
  font-family: 'Inter', sans-serif
.page-wrapper::v-deep
  div
    display: inline

.testimonial_basic
  font-size: $basic-style-base-font-size

  .author-etc
    word-break: break-word

  &.square, &.wide
    display: flex
    flex-direction: column
    justify-content: center

  &.square
    // aspec-ratio is not supported by older puppeteer
    // aspect-ratio: 1 / 1
    width: 624px
    height: 624px
  &.wide
    // aspect-ratio: 16 / 9 (+1 px)
    width: 624px
    height: 352px

.avatar
  min-width: 48px
  min-height: 48px
  max-width: 48px
  max-height: 48px
  flex-grow: 0
  background: white
  border-radius: 50%
  border: 1px solid $ueBlack
  ::v-deep svg
    width: 24px
    height: 24px
    position: relative
    top: 11px
    left: 11px
    path
      fill: $ueBlack

.headshot
  width: 48px
  height: 48px
  border-radius: 50%
  img
    border-radius: 50%
    width: 100%
    height: 100%

.author-name
  font-size: 1.25em
  font-weight: 600
  line-height: 1

.nps-label, .nps-score
  line-height: 1
.nps-score
  font-size: 34px

.page-wrapper
  font-size: 16px
.author-etc
  font-size: 14px
  line-height: 1.25
</style>
